.form-check {
  text-align: left;
}
.bg-primary {
  background-color: #032E64 !important;
}
.border-primary {
  border-color: #032E64 !important;;
}
img {
  max-width: 100%;
}

.ctooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.ctooltip + .ctooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  font-size: 16px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

table .ctooltip + .ctooltiptext {
  width: 300px;
  margin-left: -150px;
  display: block;
}



.ctooltip:hover + .ctooltiptext {
  visibility: visible;
}
.fw-lighter{ 
  color: #aaa;
}
@media (max-width: 1000px) {
  .ctooltip + .ctooltiptext {
    width: 70%;
    display: block;
    left: 15%;
  }
}